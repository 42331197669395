import {
    DeleteFilled,
    CheckCircleFilled,
    CloseCircleFilled,
} from "@ant-design/icons";
import { Button, Tooltip, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RealmContext } from "../../context/realmProvider";
import { updateAssessments } from "../../redux/assessmentsSlice";
import { ObjectId } from "../../utils";

function Remove({ entity }) {
    const [downloading, setDownloading] = useState(entity.removing);
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, description) => {
        api[type]({
            message: "Thông báo",
            description,
            placement: "topRight",
        });
    };
    const { mongo } = useContext(RealmContext);
    const dispatch = useDispatch();
    const handleOk = async () => {
        setDownloading(true);
        const email = entity.short + ".t@mg.a247.vn";
        const id = entity._id;
        const callback = encodeURIComponent(
            "https://ap-southeast-1.aws.data.mongodb-api.com/app/wowed-ijfty/endpoint/updateDownload"
        );
        const download = entity.download;
        try {
            const test = await fetch(download);
            const vary = test.headers.get("vary");
            //console.log(email, id, callback, download, vary);
            if (vary) {
                await fetch(
                    `http://localhost:8000/api/v1/handle/remove?email=${email}&id=${id}&callback=${callback}`
                );
                const ids = [];
                ids.push(ObjectId(id));
                dispatch(
                    updateAssessments({
                        ids,
                        mongo,
                        update: {
                            removing: !downloading,
                        },
                    })
                );
                openNotificationWithIcon(
                    "success",
                    "File đã được sao lưu trên Drive, bắt đầu xóa!"
                );
            } else {
                openNotificationWithIcon(
                    "error",
                    "File lưu trên Drive bất thường!"
                );
                setDownloading(false);
            }
        } catch (err) {
            openNotificationWithIcon(
                "error",
                "Đã có lỗi vui lòng liên hệ hỗ trợ!"
            );
            setDownloading(false);
        }

        //setDownloading(false);
    };
    useEffect(() => {
        setDownloading(entity.removing || false);
    }, [entity]);

    return (
        <>
            {contextHolder}
            <Tooltip title="Remove From Global">
                <Button
                    danger
                    icon={<DeleteFilled />}
                    loading={downloading}
                    size="small"
                    onClick={handleOk}
                    className="mr-1"
                    type="primary"
                />
            </Tooltip>
        </>
    );
}

export default Remove;
