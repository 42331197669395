import {
    Alert,
    Button,
    InputNumber,
    Modal,
    QRCode,
    Segmented,
    Typography,
} from "antd";
import { useState } from "react";
const { Paragraph, Text } = Typography;
function PaymentHana({ currentUser }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [text, setText] = useState(`/images/h100.jpg`);
    const [value, setValue] = useState(100);
    //50    00020101021238630010A000000727013300069704360119QRGD0071000709819020208QRIBFTTA5303704541012,750,0005802VN62070803c5063047056
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button type="link" size="small" onClick={showModal}>
                (
                {Intl.NumberFormat("en-US").format(
                    currentUser.credit != 805 ? currentUser.credit : 0
                )}
                )
            </Button>
            <Modal
                title="Chuyển credit khóa thành không thời hạn"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
            >
                Mỗi lần chuyển đổi được{" "}
                <span className="font-semibold">100 credit</span>. số credit
                khóa còn lại <span className="font-semibold">805</span>
                {/* <Segmented
                    options={["100", "200", "400", "805"]}
                    onChange={(value) => {
                        //console.log(value); // string
                        setText(`/images/h${value}.jpg`);
                        setValue(parseInt(value));
                    }}
                    value={value.toString()}
                /> */}
                <center>
                    <div className="overflow-hidden h-[580px] w-[380px] rounded-2xl my-5">
                        <div className="absolute top-[450px] left-[190px] text-red-600 text-lg">
                            <strong>Nội dung: </strong>
                            <Paragraph
                                className="inline text-red-600 text-lg"
                                copyable={{ text: "h" + value.toString() }}
                            >
                                h{value.toString()}
                            </Paragraph>
                        </div>
                        <img
                            className="mt-[-100px] ml-[-10px]"
                            style={{ width: "400px" }}
                            src={text}
                            alt=""
                        />
                    </div>
                    <Alert
                        message="Trong vòng 24h sau khi nhận được thông tin chuyển khoản từ
                    ngân hàng, hệ thống sẽ tự động cập nhật credit cho bạn."
                        className="font-semibold text-red-400"
                        type="info"
                    />
                </center>
            </Modal>
        </>
    );
}

export default PaymentHana;
