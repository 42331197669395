import React from "react";
import { useLocation } from "react-router-dom";

function NotFound(props) {
    let location = useLocation();
    // console.log(location);
    return (
        <center className="pt-[30%]">
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
        </center>
    );
}

export default NotFound;
