import {
    Alert,
    Button,
    InputNumber,
    Modal,
    QRCode,
    Segmented,
    Typography,
} from "antd";
import { useState } from "react";
const { Paragraph, Text } = Typography;
function Payment({ currentUser }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [text, setText] = useState(`/images/c100.jpg`);
    const [value, setValue] = useState(100);
    //50    00020101021238630010A000000727013300069704360119QRGD0071000709819020208QRIBFTTA5303704541012,750,0005802VN62070803c5063047056
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button type="link" size="small" onClick={showModal}>
                ({Intl.NumberFormat("en-US").format(currentUser._credit)})
            </Button>
            <Modal
                title="Thêm credit"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
            >
                Số lượng:{" "}
                <Segmented
                    options={["50", "100", "200", "300"]}
                    onChange={(value) => {
                        //console.log(value); // string
                        setText(`/images/c${value}.jpg`);
                        setValue(parseInt(value));
                    }}
                    value={value.toString()}
                />
                <center>
                    <div className="overflow-hidden h-[560px] w-[436px] rounded-2xl my-5">
                        <div className="absolute top-[470px] left-[150px] w-52 text-red-600 text-lg">
                            <strong>Nội dung: </strong>
                            <Paragraph
                                className="inline text-red-600 text-lg"
                                copyable={{ text: "c" + value.toString() }}
                            >
                                c{value.toString()}
                            </Paragraph>
                        </div>
                        <img
                            className="mt-[-145px] ml-[-18px]"
                            style={{ width: "472px" }}
                            src={text}
                            alt=""
                        />
                    </div>
                    <Alert
                        message="Trong vòng 24h sau khi nhận được thông tin chuyển khoản từ
                    ngân hàng, hệ thống sẽ tự động cập nhật credit cho bạn."
                        className="font-semibold text-red-400"
                        type="info"
                    />
                </center>
            </Modal>
        </>
    );
}

export default Payment;
